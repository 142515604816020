import { useObserver } from 'mobx-react';
import Qst from '@commonComponents/QuestionContent/QuestionContent.module.scss';
import Error from '@comComponents/molecules/ErrorValidation/ErrorValidation';
import Radio from '@comComponents/atoms/Input/Radio/Radio';
import Qna from '@comComponents/molecules/QnaBox/QnaBox';
import QText from '@comComponents/atoms/Text/QuestionText/QuestionText';
import Answer from '@comComponents/atoms/Text/SymbolText/SymbolText';

function AddressCheck(props) {
    return useObserver(() => (
        <div className={Qst.spaceBetween}>
            <div className={Qst.infoSection}>
                <div className={Qst.content}>   
                    <div className={Qst.titleWrap}>
                        <h2 className={Qst.title}>
                            {props.item.koreanName}님의 <span className={Qst.bold}>현재 주민등록상 주소</span>가 <span className={Qst.bold}>{props.item.foreignerYn ? '거소신고(외국인등록) 사실증명상 주소' : '등기부상 주소'}</span>와 같나요?
                        </h2>
                    </div>
                    <div className={Qst.addressCheck}>
                        <div className={Qst.address}>{props.item.koreanName}님의 {props.item.foreignerYn ? '거소신고(외국인등록) 사실증명상 주소' : '등기부상 주소'}</div>
                        <div className={Qst.originAddress}>{props.item.originAddress}</div>
                        {console.log(props.item)}
                        <div className={Qst.originCauseDate}>{props.item.originCauseDate} {props?.item?.originCauseDateByEstablishmentYn ? '설립' : props.item.foreignerYn ? '변경' : '전입'}</div>
                    </div>
                    <div className={Qst.addressCheckExpian}>주소변동시 <span className={Qst.bold}>{props.item.originCauseDate} 이후 주소변동내역</span>을 모두 입력해 주세요.</div>
                    <ul className={Qst.answerList}>
                        <li><Radio className={Qst.answerLabel} value={true} activeBackground checked={props.item.addressSameYn === true} onChange={(e) => props.parentVm.setAddressSameYn(e, props.idx)} name={`${props.name}[${props.idx}].addressSameYn`}>네. 같습니다.</Radio></li>
                        <li><Radio className={Qst.answerLabel} value={false} activeBackground checked={props.item.addressSameYn === false} onChange={(e) => props.parentVm.setAddressSameYn(e, props.idx)} name={`${props.name}[${props.idx}].addressSameYn`}>아니오. 다릅니다.</Radio></li>
                    </ul>
                    <Error name={`${props.name}[${props.idx}].addressSameYn`} value={props.item.addressSameYn} errorCase={{required:'현재 주민등록상 주소가 등기부상 주소와 같은지 선택해 주세요.'}}/>
                </div>
            </div>
            <div className={Qst.qnaSection}>
                <Qna tip={props.item.foreignerYn ? '기존 등기부상 대표 변경시' : null} className={props.item.foreignerYn ? Qst.qnaTitle : Qst.noTitle}>
                    <div className={Qst.qnaBox}>
                        <QText>대표 주소가 변경된 경우 반드시 등기해야 하나요?</QText>
                        <div className="answerBox">
                            <Answer className={Qst.answer}>대표 주소가 변경된 경우 반드시 등기해야 합니다.</Answer>
                            <p className={Qst.explain}>
                                대표이사 주소가 변경된 경우, 전입신고일로부터 2주 내에 대표자주소변경등기를 접수해야 합니다. 만약 시기를 놓치는 경우, 매월 5~10만원의 과태료가 부과되므로 최대한 빠르게 등기를 접수해주세요.
                            </p>
                        </div>
                    </div>
                    <div className={Qst.qnaBox}>
                        <QText>대표 주소가 변경된 경우, 등기할 때 추가비용이 드나요?</QText>
                        <div className="answerBox">
                            <Answer className={Qst.answer}>네. 견적에 미리 포함된 경우가 아니라면, 추가 비용이 발생합니다.</Answer>
                            <p className={Qst.explain}>
                                견적에 대표자주소변경등기 견적이 포함되지 않은 경우, 60,000원(부가세 제외)의 수수료가 추가됩니다. 또한 여러 명의 대표님의 주소가 각각 변경된 경우, 인원수 X 60,000원(부가세 제외)의 수수료가 추가됩니다. 덧붙여 관외지점이 있는 경우, 관외지점 등기부도 변경해야 하기 때문에, 관외지점마다 추가비용이 발생합니다.
                            </p>
                            <p className={Qst.explain}>
                                이미 견적에 대표자주소변경 수수료가 포함된 경우에는 추가비용이 발생하지 않습니다.
                            </p>
                        </div>
                    </div>
                    <div className={Qst.qnaBox}>
                        <QText>대표가 그만두는 경우에도 대표 주소변경 등기를 해야 하나요?</QText>
                        <div className="answerBox">
                            <Answer className={Qst.answer}>네. 대표가 그만두는 경우에도 대표 주소변경 등기를 접수해야 합니다.</Answer>
                            <p className={Qst.explain}>
                                대표를 그만두는 경우에도, 사임/퇴임 등기와 동시에 대표자주소변경등기를 접수해야 합니다. 만약 동시에 신청하지 않는 경우, 법원에서는 보정명령을 내리고 사임/퇴임 등기를 마쳐주지 않습니다.
                            </p>
                        </div>
                    </div>
                    <div className={Qst.qnaBox}>
                        <QText>대표님의 과거 주민등록상 집주소를 모두 등기해야 하나요?</QText>
                        <div className="answerBox">
                            <Answer className={Qst.answer}>대표님의 과거 주민등록상 집주소 변동내역은 모두 등기해야 합니다.</Answer>
                            <p className={Qst.explain}>
                                관련법령상 법인 대표자의 과거 주민등록상 집주소 변동내역은 모두 등기해야 하고, 반드시 한꺼번에 등기신청을 해야 합니다. 만일 과거 변동내역 등기는 생략하고, 최신 주민등록상 집주소만 기재하여 등기신청을 하는 경우, 법원에서는 보정명령을 내리고, 등기접수를 받아주지 않습니다. 이 경우 5~7일 시간이 더 걸리게 되고, 헬프미 보정명령 처리 수수료(60,000원(부가세 제외))가 추가되므로, 주의 부탁드립니다.
                            </p>
                        </div>
                    </div>
                </Qna>
            </div>
        </div>
    ));
}

export default AddressCheck;